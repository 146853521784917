@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

@include keyframeFadeOut();

$S-tooltip-arrow-height: 8px;

.tag_list {
  padding: 0 16px;

  display: flex;
  @include scroll-wrap(x);

  .has_tooltip_area {
    margin-bottom: 50px;
    position: relative;

    &:nth-child(1) {
      .tooltip {
        p {
          transform: none;
          left: 0;
        }
      }
    }
  }

  .item {
    @include place-default-quick-link;

    &.is_bold {
      @include font-size-14-bold();
    }

    &.red_dot {
      span {
        padding-right: 6px;

        &::after {
          content: ' ';
          position: absolute;
          top: 1px;
          right: 0;
          display: inline-block;
          width: 4px;
          height: 4px;
          border-radius: 4px;
          background: $red-500;
        }
      }
    }
  }
  .tooltip {
    position: absolute;
    top: 100%;
    width: 100%;
    white-space: nowrap;
    @include font-size-12-bold();

    animation: fadeout 0.5s ease-in-out forwards 4s;

    .arrow {
      position: absolute;
      background-color: $tmobi-blue-400;
      display: inline-block;
      height: 16px;
      width: 16px;
      left: calc(50% - 8px);
      top: 2px;
      z-index: 1;
      border-radius: 2px;
      transform: rotate(45deg);
    }

    &.is_hide {
      animation: none;
      transition: opacity 0.5s ease-in-out;
      opacity: 0;
    }

    p {
      z-index: 0;
      margin-top: $S-tooltip-arrow-height;
      position: absolute;
      transform: translate(-50%, 0px);
      left: 50%;
      top: 0;
      padding: 9px 12px;
      background-color: $tmobi-blue-400;
      pointer-events: none;
      color: $wb-white;
      border-radius: 8px;
      filter: drop-shadow(0px 2px 4px $black-alpha-200);
    }
  }
}

body[data-orientation='landscape'] {
  .tag_list::after {
    content: ' ';
    flex-basis: 370px;
    flex-shrink: 0;
  }
}
