@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.content_wrap {
  padding: 12px 20px 68px;

  .button_group {
    margin-bottom: 16px;
    .title {
      margin-bottom: 12px;
      color: $gray-700;
      @include tmobi-font-bold(16px, 26px);
    }
    .list {
      white-space: normal;
      .rounded_button {
        margin-right: 6px;
        margin-bottom: 10px;
        color: $gray-900;
        padding: 11px 14px;
        border: 1px solid $gray-200;
        border-radius: 40px;
        position: relative;
        @include tmobi-font-medium(14px, 13px);
        @include touch-guide(40px);
        &.checked {
          @include tmobi-font-bold(14px, 13px);
          background-color: $gray-100;
          border-color: $gray-500;
        }
      }
    }
  }
  &.blue {
    .button_group {
      .list {
        .rounded_button {
          &.checked {
            color: $tmobi-blue-600;
            background-color: $tmobi-blue-100;
            border-color: $tmobi-blue-300;
          }
        }
      }
    }
  }
}

.bottom_wrap {
  .buttons {
    padding: 0 20px 16px 20px;
    display: flex;

    .reset {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      background-color: $gray-100;
      flex-grow: 0.5;
      border-radius: 8px;
      position: relative;
      @include touch-guide(5px);
    }

    .confirm {
      border: none;
      height: 52px;
      border-radius: 8px;
      background-color: $tmobi-blue-500;
      text-align: center;
      color: $wb-white;
      flex-grow: 2;

      position: relative;
      @include touch-guide(5px);
      @include font-size-18();
    }
  }
}

.region_button_wrap {
  display: flex;
}

.region_select_button {
  width: 100%;
  padding: 14px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $gray-200;
  margin-right: 8px;
  border-radius: 8px;
  position: relative;
  @include tmobi-font-medium(17px);
  @include touch-guide(8px);
  &.disabled {
    border: none;
    background-color: $gray-50;
    color: $gray-300;
  }
}
