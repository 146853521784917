@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

@include keyframeBlink();

.wrap {
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-top: 30px;
  padding-bottom: 120px;
}

.around_me_content_item {
  padding: 0 30px;

  .title {
    width: 40%;
    height: 22px;
    margin-bottom: 8px;
    @include gradient;
  }

  .desc {
    width: 100%;
    height: 20px;
    @include gradient;
  }
}

.discovery_content_wrap {
  padding: 0 20px;
  .row {
    margin-bottom: 12px;
    display: flex;
    width: 100%;
    gap: 8px;
    .col {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 2px;
      .content_img {
        height: 110px;
        border-radius: 8px;
        margin-bottom: 8px;
        @include gradient;
      }
      .content_s1 {
        width: 50%;
        height: 24px;
        @include gradient;
      }
      .content_s2 {
        height: 20px;
        width: 90%;
        @include gradient;
      }
      .content_s3 {
        height: 20px;
        width: 60%;
        @include gradient;
      }
    }
  }
}

.skeleton_item {
  @include gradient;
  @include infiniteBlink;
  min-width: 10px;
  min-height: 10px;
  border-radius: 4px;
  border: none;
}
