@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.params_wrap {
  padding: 8px 20px;
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $text-basic-default;
  @include tmobi-font-bold(16px, 24px);

  .state_text_button {
    display: flex;
    align-items: center;
    gap: 4px;
    position: relative;
  }
}

.filters_wrap {
  overflow-x: scroll;
  @include scroll-wrap(x);
  padding: 8px 0;
  position: relative;

  .button_wrap {
    display: flex;
    align-items: center;

    .button_group {
      white-space: nowrap;
      .button_all {
        position: relative;
        @include touch-guide(50%);

        display: flex;
        justify-content: center;
        align-items: center;

        width: 32px;
        height: 32px;
        margin-right: 4px;

        border-radius: 50%;
        border: 1px solid $border-input-stroke-default;
      }

      .button_filter {
        position: relative;
        @include touch-guide(16px);
        padding: 9px 12px;
        border-radius: 40px;

        color: $text-basic-default;
        @include tmobi-font-normal(14px, 13px);

        border: 1px solid $border-input-stroke-default;
        &:not(:last-child) {
          margin-right: 4px;
        }
      }
      .filter_checked {
        @include tmobi-font-bold(14px, 13px);
        color: $gray-900;
        border: 1px solid $gray-600;
        background-color: $gray-100;
      }
      &:first-child {
        margin-left: 20px;
      }
      &:last-child {
        padding-right: 20px;
      }
    }
    .divider {
      @include override-svg($border-input-stroke-default, 9px, 22px);
      display: inline-flex;
      align-items: center;
      margin: 0 4px;
    }
  }
}

.distance_wrap {
  padding: 0 20px;
  margin: 4px 0 16px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;

  .button {
    position: relative;
    @include touch-guide();
    display: flex;
    align-items: center;
    gap: 2px;
    .text {
      color: $gray-400;
      @include tmobi-font-medium(13px, 20px);
    }
    .text_on {
      color: $tmobi-blue-500;
    }
  }
}
