@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  transform: translateY(-268px); // 248(height) + 20(padding)
  background-color: transparent;
  border-top-right-radius: var(--handle-border-radius);
  border-top-left-radius: var(--handle-border-radius);
  z-index: $Z-drawer;
}

.flicking_wrap {
  width: 100%;
}

.card {
  width: 100%;
  margin: 0 4px 20px 4px;
  border-radius: 20px;

  background-color: $wb-white;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16);
}
