@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.header {
  z-index: $Z-header;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;

  .vsm_compass {
    position: absolute;
    left: 13px;
    top: calc(100% + 8px); // 나침반 위의 주변검색 링크 하단에 이미 5px 여백 포함됨
  }

  .back {
    @include override-svg($gray-900, 28px, 28px);
    @include touch-guide(24px);
    display: none;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 16px;
    top: 8px;
    z-index: 100;
  }

  .search_button {
    margin: 8px 16px 12px 16px;
    padding: 0 20px;

    width: calc(100% - 32px);
    height: 48px;
    text-align: left;
    border-radius: 24px;
    background: $wb-white;
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.12);

    @include font-size-18();
    color: $text-basic-disabled;

    .content {
      @include line-clamp(1);
    }
  }

  .top_shadow {
    position: absolute;
    width: 100%;
    height: 84px;
    top: 56px;
    left: 0;
    z-index: -1;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.12) 100%);
    display: block;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.1s;
    &.show {
      opacity: 1;
    }
  }

  &.stand_alone_view {
    .back {
      display: inline-flex;
    }
    .search_button {
      padding-left: 40px;
    }
  }
}

body[data-orientation='landscape'] {
  .header {
    .search_button {
      width: calc(100% - 408px);
    }
  }
}
