@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.sub_info_wrap {
  display: flex;
  align-items: center;
  gap: 2px;
  margin-top: 4px;
}

.distance {
  @include tmobi-font-normal(13px);
  color: $gray-500;
  flex-shrink: 0;
}

.number {
  @include tmobi-font-bold(13px);
}

.tag {
  margin-right: 2px;
  margin-bottom: 1px;
}
