@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

@include keyframeBlink();

.error_wrap {
  padding-top: 200px;
}

.item {
  border-bottom: 1px solid $gray-100;

  &.is_selected {
    background: $tmobi-blue-50;
  }
}

.header {
  z-index: $Z-header;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;

  .title {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: $gray-900;
    @include font-size-18-bold();
  }
  .vsm_compass {
    position: absolute;
    left: 12px;
    top: calc(100% + 12px);
  }

  .title_holder {
    width: 140px;
    height: 24px;

    @include gradient;
    @include infiniteBlink;
    transition: opacity ease-in-out 0.3s;
  }
}

.landscape {
  display: none;
}

body[data-orientation='landscape'] {
  .header {
    left: inherit;
    width: 100vw;
    z-index: $Z-category-header;

    div {
      box-shadow: none;
    }
  }
  .landscape {
    display: inline-flex;
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  .portrait {
    display: none;
  }
}
