@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

/**
티플레이스홈 에러 및 장소 없는 경우 style
*/

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 24px;
  padding: 50px 20px 100px;

  .desc_wrap {
    @include tmobi-font-medium(18px, 150%);
    color: $gray-600;
    margin-bottom: 24px;
    text-align: center;
  }

  .gray_button {
    color: $gray-900;
    padding: 8px 16px;
    border: 1px solid $gray-300;
    border-radius: 8px;
    position: relative;
    @include touch-guide(8px);
    @include tmobi-font-normal(16px, 24px);
  }

  .blue_button {
    color: $wb-white;
    padding: 8px 16px;
    background-color: $tmobi-blue-500;
    border-radius: 8px;
    position: relative;
    @include touch-guide(8px);
    @include tmobi-font-normal(16px, 24px);
  }
}
