@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

@include keyframeFadeOut();

.header_wrap {
  @include font-size-14();
  display: flex;
  flex-direction: column;

  &.has_filter {
    height: initial;

    .filter_wrap {
      display: flex;
    }
  }

  .filter_wrap {
    display: none;
    height: 48px;
    box-sizing: content-box;
    @include scroll-wrap(x);
    border-bottom: 1px solid $gray-100;
    align-items: center;
    background-color: $gray-50;

    &::after {
      content: ' ';
      width: 16px;
      display: inline-flex;
      flex-shrink: 0;
    }

    &::before {
      content: ' ';
      width: 16px;
      display: inline-flex;
      flex-shrink: 0;
    }

    .filter {
      height: 30px;
      display: inline-flex;
      align-items: center;
      padding: 5px 10px;

      &:has(.icon_fold) {
        padding: 5px 8px 5px 10px;
      }
      flex-shrink: 0;
      margin-right: 4px;
      position: relative;
      border: 1px solid $gray-200;
      color: $gray-900;
      border-radius: 18px;
      background-color: $wb-white;

      @include font-size-14();
      @include touch-guide(18px);

      &.on {
        @include font-size-14-bold();
        background-color: $gray-50;
        border-color: $gray-400;
        color: $gray-900;
      }

      &.multiple {
        overflow: hidden;
      }

      .text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1;
      }

      .icon_fold {
        @include override-svg($gray-500, 12px, 12px);
        width: 14px;
        height: 14px;
        margin-left: 2px;
      }

      &:last-child {
        margin-right: 0px;
      }
    }

    .divider {
      margin-right: 4px;
    }
  }
}
