@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.wrap {
  padding: 10px;
  word-break: break-all;

  button {
    border: 1px solid $gray-400;
    color: $gray-400;
    padding: 3px;
    border-radius: 5px;
    @include font-size-11-bold();
    margin-right: 5px;

    &:disabled {
      opacity: 0.2;
    }

    &.is_selected {
      background-color: $tmobi-blue-200;
    }
  }

  h3 {
    @include font-size-16-bold();
    background-color: $gray-100;
    padding: 8px 0px;
    margin-top: 40px;
    margin-bottom: 10px;
  }

  .preview {
    @include font-size-11();
    margin-bottom: 10px;
  }

  .link_wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    button {
      padding: 5px 7px;
      border: 1px solid $gray-500;
      border-radius: 3px;
      background-color: $wb-white;
      margin-right: 10px;
      margin-bottom: 10px;
      min-width: 40px;
      min-height: 40px;
      display: flex;
      flex-direction: column;

      em {
        color: $gray-300;
        font-size: 12px;
      }

      &.none {
        border: 1px solid $C-tmobi-pink-300;
        color: $C-red-600;

        em {
          color: $C-tmobi-pink-300;
        }
      }

      &.is_selected {
        background-color: $tmobi-blue-200;
      }
    }
  }

  .url_input {
    width: 250px;
    height: 30px;
  }

  .url_button {
    border: 1px solid $gray-300;
    border-radius: 3px;
    background-color: $wb-white;
    height: 30px;
    width: 40px;
  }
}

.copy_button {
  margin-left: 8px;
}

.quick_search_wrap {
  padding: 16px 8px;
  font-size: 14px;
  user-select: text;

  .desc {
    font-size: 14px;
    color: $gray-500;
    margin-bottom: 8px;
    width: 80%;

    span {
      user-select: text;
      margin-right: 8px;
      white-space: nowrap;

      &:hover {
        background-color: $gray-100;
      }
    }
  }

  .result_wrap {
    display: flex;
    flex-direction: column;
  }
}

.link {
  cursor: pointer;
  color: $tmobi-blue-500;
  padding: 4px 0;

  &:hover {
    text-decoration: underline;
  }
}

span {
  user-select: text;
}

.notice {
  color: red;
  @include font-size-12-bold();
}

.guide {
  color: $green-500;
  @include font-size-14-bold();
}

.tail_param_wrap {
  padding: 16px 0;
}
