@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  margin: 80px 0;
}

.text_wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 54px;

  @include font-size-18-bold();
  line-height: 150%;

  .highlight {
    color: $tmobi-blue-500;
  }
}

.button_wrap {
  display: flex;
  justify-content: center;
  align-items: center;

  .button {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    padding: 0px 18px;

    border-radius: 8px;
    background-color: $tmobi-blue-100;

    color: $tmobi-blue-600;
    @include font-size-16;
    @include touch-guide(8px);
  }
}
