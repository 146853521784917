@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.content_scroll_wrap {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.pad {
  background-color: $gray-100;
  display: flex;
  justify-content: center;
  padding: 40px;
}

.btn_to_top {
  @include touch-guide($borderRadius: 24px);
  position: relative;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  z-index: $Z-btn-to-top;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $wb-white;
  transition: opacity ease-in-out 0.2s;
  opacity: 0;
  visibility: hidden;

  border: 1px solid $gray-200;

  @include override-svg($gray-700, 24px, 24px);

  &.show {
    opacity: 1;
    visibility: visible;
  }
}
