@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.header_wrap {
  @include font-size-14();
  display: flex;
  flex-direction: column;

  &.has_filter {
    height: initial;

    .filter_wrap {
      display: flex;
    }
  }

  .filter_menu {
    margin-left: 2px;
  }

  .filter_wrap {
    display: none;
    padding-top: 16px;
    padding-right: 16px;
    box-sizing: content-box;
    @include scroll-wrap(x);
    align-items: center;
    background: linear-gradient(180deg, #f8f9fa 0%, #fff 100%), #fff;

    &::before {
      content: ' ';
      width: 12px;
      display: inline-flex;
      flex-shrink: 0;
    }

    .filter {
      height: 32px;
      display: inline-flex;
      align-items: center;
      padding: 5px 12px;

      &.menu {
        width: 32px;
        height: 32px;
        padding: 7px;
      }

      &.has_content {
        width: auto;
        height: 32px;
        padding: 7px 10px 7px 6px;
      }

      &:has(.icon_fold) {
        padding: 5px 8px 5px 10px;
      }
      flex-shrink: 0;
      margin-right: 4px;
      position: relative;
      border: 1px solid $gray-200;
      color: $gray-900;
      border-radius: 18px;
      background-color: $wb-white;

      @include font-size-14();
      @include touch-guide(18px);

      &.on {
        @include font-size-14-bold();
        background-color: $gray-100;
        border-color: $gray-600;
        color: $gray-900;
      }

      &.multiple {
        overflow: hidden;
      }

      .text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1;
      }

      .icon_fold {
        @include override-svg($gray-500, 12px, 12px);
        width: 14px;
        height: 14px;
        margin-left: 2px;
      }

      &:last-child {
        margin-right: 0px;
      }
    }

    .divider {
      display: inline-flex;
      align-items: center;
      justify-content: center;

      margin-right: 4px;
      @include override-svg($gray-100, 12px, 30px);
    }
  }
}
