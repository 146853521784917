@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.title_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title_container {
    display: flex;
    align-items: center;
    min-width: 0;
    gap: 4px;
  }

  .title {
    min-width: 0;

    @include line-clamp(2);
    @include tmobi-font-bold(17px);

    &.photo_view {
      @include tmobi-font-bold(16px);
    }
  }

  .category {
    white-space: nowrap;

    color: $gray-400;
    @include font-size-12();
  }
}
