@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

@include keyframeFadeOut();

.wrap {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;

  .tmds_tag {
    @include tmobi-font-bold(10);
    height: 19px;
  }

  .title_wrap {
    @include line-clamp(2);
    text-overflow: ellipsis ' [..]';
    display: flex;
    justify-content: space-between;
    margin: 0 16px 2px 16px;

    .tag {
      position: relative;
      bottom: 2.5px;

      margin-right: 6px;
    }

    .text_wrap {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 4px;
      max-width: calc(100% - 64px); // 길찾기 아이콘(48px) + 여유 공간

      &.has_favorite {
        max-width: calc(100% - 48px); // 즐겨찾기 아이콘 크기
      }
    }

    .title_content {
      display: flex;
      align-items: center;
      gap: 4px;

      min-width: 0; // flex item 내부의 ellipsis를 위해 필요
      flex: 1;
    }

    .ad_tag {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;

      width: 22px;
      height: 16px;

      border-radius: 9px;
      border: 1px solid rgba(17, 48, 99, 0.2);

      color: $tmobi-blue-800;
      opacity: 0.8;
      text-align: center;
      @include tmobi-font-bold(9px);
    }

    .title {
      @include tmobi-font-bold(17px);
      word-break: break-word;
      @include text-ellipsis();
    }

    .category {
      position: relative;
      @include text-ellipsis();

      @include font-size-12();
      color: $gray-400;
    }

    .star {
      color: 'red';
    }

    .button_wrap {
      position: absolute;
      top: 0;
      right: 16px;

      .route {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 48px;
        height: 48px;
        border-radius: 50%;

        background-color: $tmobi-blue-700;
      }
    }
  }

  .local_ad {
    .title {
      color: $tmobi-blue-800;
    }
  }

  .info_wrap {
    display: flex;
    align-items: center;
    margin: 0 16px;

    @include font-size-14();
    color: $gray-600;

    .count {
      color: $tmobi-blue-400;
    }
  }

  .ad_info {
    margin-top: 4px;
    margin-left: 16px;
    color: $tmobi-pink-700;
    @include tmobi-font-medium(13px);
    @include line-clamp(1);
  }

  .special_info {
    margin-top: 6px;
  }

  .special_action {
    margin-top: 10px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &.is_hide {
      display: none;
    }

    .tag_list {
      display: inline-flex;
      flex-wrap: wrap;
      margin-left: 16px;

      .tag {
        display: inline-flex;
        height: 18px;
        flex-shrink: 0;
        margin-left: 4px;

        &:first-child {
          margin: 0;
        }
      }
    }
  }
}
