@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 16px 16px 20px;

  border-bottom: 1px solid $gray-100;
}

.filter {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    color: $gray-300;

    @include font-size-14();

    &.clicked {
      color: $gray-900;
      @include font-size-14-bold();

      path {
        fill: $gray-900;
      }
    }
  }
}

.mode {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;

  .list {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
  }

  .grid {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
  }

  .divider {
    display: flex;
    align-items: center;

    @include override-svg('gray200', 9px, 22px);
  }
}
