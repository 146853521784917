@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.modal_wrap {
  display: flex;
  flex-direction: column;

  height: auto;
  // TODO: 영업중 필터 추가시 height 변경 424px;
  padding: 12px 20px calc(12px + env(safe-area-inset-bottom)) 20px;

  &.is_landscape {
    height: 100%;
    justify-content: space-between;

    .content_container {
      height: 80%;
      @include scroll-wrap(y);
    }
  }

  .container {
    margin-bottom: 40px;
  }

  .title {
    @include font-size-16-bold();
    margin-bottom: 12px;
  }

  .filter_wrap {
    display: flex;
    gap: 6px;
    flex-wrap: wrap;
    width: 300px;
    row-gap: 10px;

    &.top {
      row-gap: 12px;

      @media screen and (max-width: 360px) {
        width: 200px;
      }
    }

    .filter {
      height: 40px;
      display: inline-flex;
      align-items: center;
      padding: 7px 16px;

      flex-shrink: 0;
      position: relative;
      border: 1px solid $gray-200;
      color: $gray-900;
      border-radius: 20px;
      background-color: $wb-white;

      @include font-size-14();
      @include touch-guide(18px);

      &.on {
        @include font-size-14-bold();
        background-color: $gray-50;
        border-color: $gray-400;
        color: $gray-900;
        border-color: $gray-600;

        .text {
          @include font-size-16-bold();
        }
      }

      .text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1;

        @include font-size-16();
      }
    }
  }
}

.bottom_area {
  width: 100%;
  background: $wb-white;

  .buttons {
    display: flex;
    gap: 8px;
    margin-top: 10px;
    @include font-size-18();

    .reset {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 115px;
      height: 52px;
      gap: 6px;

      background-color: $gray-100;
      flex-grow: 1;
      border-radius: 8px;

      position: relative;
      @include touch-guide(8px);
    }

    .confirm {
      border: none;
      width: 227px;
      height: 52px;
      border-radius: 8px;
      background-color: $tmobi-blue-500;
      text-align: center;
      color: $wb-white;
      flex-grow: 2;

      position: relative;
      @include touch-guide(8px);
    }

    .count {
      width: 15px;
      height: 25px;
      margin-left: 6px;
      @include font-size-18-bold();
    }
  }
}
