@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.active {
  width: 26px;
  height: 16px;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);

  color: $tmobi-blue-600;
  text-align: 'center';
  @include tmobi-font-heavy(16px);

  &.is_day {
    color: $wb-white;
  }
}

.label_wrap {
  width: 24px;
  height: 22px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.normal {
  color: $wb-white;
  text-align: 'center';
  @include tmobi-font-heavy(10px);
}
