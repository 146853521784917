@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.wrap {
  position: relative;
}
.content {
  @include touch-guide();
  position: relative;
  width: 100%;
}
.thumb {
  @include image-holder($width: 100%, $height: 110px);
  position: relative;
  border-radius: 8px;

  .img {
    width: inherit;
    height: inherit;
    border-radius: inherit;
  }
  .tag {
    position: absolute;
    top: 4px;
    left: 4px;

    span {
      @include tmobi-font-bold(12px, 22px);
      padding: 0 6px;
    }
  }
  .route_num_tag {
    position: absolute;
    top: 10px;
    left: 10px;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.6);
    color: $wb-white;
    padding: 4px;
    @include tmobi-font-normal(11px, 11px);
    em {
      @include tmobi-font-bold(11px, 11px);
    }
  }
}
.info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px 0;
  margin: 8px 4px 0;

  .name {
    @include tmobi-font-bold(16px, 24px);
    display: block;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $gray-900;
    text-align: left;
  }
  .category {
    @include tmobi-font-medium(14px, 20px);
    color: $gray-400;
  }
  .price {
    @include tmobi-font-medium(14px, 20px);
    color: $tmobi-blue-600;
  }
}
.favorite {
  @include touch-guide();
  position: absolute;
  right: 8px;
}
