@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.page_wrap {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  &.no_map {
    position: static;
  }
}

.map_wrap {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: $Z-map;

  @include map-background;
}

.list_wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  transform: translateY(-450px);
  background-color: $wb-white;
  border-top-right-radius: var(--handle-border-radius);
  border-top-left-radius: var(--handle-border-radius);
  z-index: $Z-drawer;
  box-shadow: 0px -1px 3px $C-wb-transparent-300;

  &.is_hide {
    height: auto;
  }

  .list_header {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: $Z-drawer-header;
    top: 0;
    left: 0;

    .handle_wrap {
      display: flex;
      height: 20px;
      justify-content: center;
      align-items: center;
      touch-action: none;

      .handle {
        width: 34px;
        height: 4px;
        border-radius: 4px;
        background-color: $gray-100;
      }
    }
  }

  .scroll_wrap {
    overflow-y: scroll;
    overflow-x: hidden;
    scroll-behavior: smooth;
    flex: 1;

    .pad {
      background-color: $gray-50;

      &.is_white {
        background-color: $wb-white;
      }
    }

    &.is_scroll_disable {
      overflow-y: hidden;
    }

    .list_bottom_padding {
      display: flex;
      justify-content: center;
      align-items: flex-end;

      height: 150px;
      margin-bottom: 200px;

      .fixed_top_btn {
        position: relative;
        @include touch-guide($borderRadius: 24px);
        width: 48px;
        height: 48px;
        border-radius: 100%;
        z-index: $Z-btn-to-top;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $wb-white;

        filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));

        @include override-svg($gray-700, 24px, 24px);
      }
    }
  }
}

.floating_wrap {
  opacity: 1;
  transition: opacity ease-in-out 0.2s;

  &.hidden {
    opacity: 0;
  }

  &.disable {
    display: none;
  }

  .current_button {
    position: absolute;
    z-index: $Z-current-button;
    left: 16px;
    top: -56px; // width 40 + bottom 16
  }

  // https://tmobi.atlassian.net/browse/LCC-1920
  // .tnow_button {
  //   @include font-size-14-bold();
  //   position: absolute;
  //   z-index: $Z-current-button;

  //   width: 92px;
  //   height: 36px;

  //   top: -52px;
  //   right: 17px;

  //   bottom: 0;
  //   bottom: env(safe-area-inset-bottom);

  //   border: 1px solid $C-search-tnow-landing;
  //   color: $C-search-tnow-landing;
  //   background-color: $wb-white;
  //   border-radius: 18px;
  //   box-shadow: 0px 1px 3px 0px $black-alpha-300;
  // }

  &.landscape {
    display: none;

    .current_button,
    .save_button {
      top: calc(-56px - env(safe-area-inset-bottom));
    }

    // https://tmobi.atlassian.net/browse/LCC-1920
    // .tnow_button {
    //   top: calc(-52px - env(safe-area-inset-bottom));
    //   right: 382px; // 360px + 12px + 10px;
    // }
  }
}

.btn_to_top {
  @include touch-guide($borderRadius: 24px);
  position: fixed;
  bottom: 80px;
  right: 50%;
  margin-right: -24px;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  z-index: $Z-btn-to-top;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $wb-white;
  transition: opacity ease-in-out 0.2s;
  opacity: 0;
  visibility: hidden;

  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));

  @include override-svg($gray-700, 24px, 24px);

  &.show {
    opacity: 1;
    visibility: visible;
  }
}

body[data-orientation='landscape'] {
  .floating_wrap {
    z-index: $Z-floating;

    &.landscape {
      display: inline-flex;
      position: fixed;
      bottom: 0;
      width: 100%;
    }

    &.portrait {
      display: none;
    }
  }

  .list_wrap {
    width: $S-landscape-drawer-width;
    right: $S-landscape-drawer-margin-right;
    margin-top: var(--top-padding);
    height: calc(100% - var(--top-padding));
    border-top-left-radius: var(--handle-border-radius);
    border-top-right-radius: var(--handle-border-radius);

    .handle_wrap {
      .handle {
        display: none;
      }
    }
  }

  .btn_to_top {
    right: 190px;
    @include override-svg($gray-700, 24px, 24px);
  }

  .hybrid_page {
    .list_wrap {
      margin-top: calc(var(--top-padding));
      height: calc(100% - var(--top-padding));
      border-top-left-radius: var(--handle-border-radius);
      border-top-right-radius: var(--handle-border-radius);
    }
  }
}

.card_wrap {
  width: 100%;
}

.card {
  width: 100%;
  margin: 0 5px 20px 5px;
  border-radius: 20px;

  background-color: $wb-white;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16);
}

.gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 140px;
  z-index: $Z-floating;

  .top {
    height: 50px;
    background: linear-gradient(180deg, rgba(248, 249, 250, 0) 0%, $gray-200 100%);
  }

  .middle {
    height: 40px;
    background: $gray-200;
  }

  .bottom {
    height: 50px;
    background: linear-gradient(180deg, $gray-200 0%, rgba(255, 255, 255, 1) 100%);
  }

  &.night {
    .top {
      background: linear-gradient(180deg, rgba(84, 90, 99, 0) 0%, rgba(84, 90, 99, 1) 100%);
    }

    .middle {
      background: rgba(84, 90, 99, 1);
    }

    .bottom {
      background: linear-gradient(180deg, rgba(84, 90, 99, 1) 0%, rgba(255, 255, 255, 1) 100%);
    }
  }
}
