@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.area_warp {
  width: 100%;
  height: 100px;
  padding: 16px 0 16px 20px;
  background-color: $gray-50;
  overflow-y: scroll;

  .inner_wrap {
    display: flex;
    flex-direction: column;
  }

  .line_wrap {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    white-space: nowrap;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .item {
    height: 30px;
    line-height: 30px;
    margin-right: 6px;
    padding: 0 10px;
    border-radius: 16px;
    border: 1px solid $gray-200;
    background-color: $wb-white;
  }
}
