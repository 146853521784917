@charset "UTF-8";

// # name Convention
//  $F = font
//  $C = color
//  $Z = z-index
//  $S = size

$C-white-tr-00: rgba(255, 255, 255, 0);

$Z-gradient: 10;
$Z-dimmed: 500;
$Z-custom-layer: 501;
$Z-floating: 1;
$Z-current-button: 1;
$Z-tnow-tooltip: 20;
$Z-btn-to-top: 11;
$Z-ignore: -999;

$Z-map: 1;
$Z-drawer: 11;
$Z-drawer-header: 12;
$Z-category-header: 12;
$Z-header: 11;
$Z-compass: 12;
$Z-skeleton: 20;
$Z-popup-indicator: 2;
$Z-tooltip-popup: 13;

$Z-refresh-button: 10;
$Z-banner-debug: 99;
$Z-infinite-top: 999999999;

// search
$Z-suggest-wrap: 100;
$Z-floating-bottom-buttons: 500;
$Z-touch-guide: 30;
$Z-ad-banner: 1; // asum 배너 z-index 96

// service color
$C-thmbd-tpl-c-point: #aadcff;
$C-thmbd-tpl-c-bg: #2d375f;
$C-thmbd-refresh: rgba(255, 255, 255, 0.96);

$C-tnow-header-bg: #f9f9f9;
$C-tnow-slider-bg: #d7d7d7;
$C-search-pick-map-address: rgba(255, 255, 255, 0.9);
$C-drawer-top-bg: rgba(161, 163, 166, 0.3);

$C-ad-text: #2d557d;
$C-ad-border: rgba(45, 85, 125, 0.6);
$C-recent-poi-icon-color: #96beff;
$C-search-tnow-landing: #09409a;
$C-poi-list-item-distance: #1d3055;

$S-landscape-drawer-width: 360px;
$S-landscape-drawer-margin-right: 12px;

// tmds v1.2 color
$C-wb-transparent-300: rgba(0, 0, 0, 0.1);
$C-wb-transparent-600: rgba(0, 0, 0, 0.4);
$C-tmobi-green-600: #038591;
$C-tmobi-pink-300: #ffdbf4;
$C-tmobi-pink-400: #ff70c8;
$C-tmobi-pink-600: #c9388d;
$C-red-600: #a63019;
$C-yellow-500: #ffc219;
$C-ev-blue-600: #007fad;
