@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.wrap {
  position: absolute;
  bottom: 20px;
  width: 100%;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  .button_wrap {
    width: 194px;
    height: 40px;
    position: relative;
    margin-bottom: -20px;
    pointer-events: all;

    opacity: 0;
    transition: opacity ease-out 0.4s, margin-bottom cubic-bezier(0.25, 1, 0.5, 1) 0.8s;

    &.fade_in {
      opacity: 1;
      margin-bottom: 0px;
    }

    .button {
      width: 100%;
      height: 100%;

      border-radius: 30px;
      background: linear-gradient(90deg, #005f40 0%, #02875c 100%);
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16);

      display: flex;
      align-items: center;

      position: relative;
      @include touch-guide(30px);

      .icon {
        line-height: 20px;
        display: flex;
        margin-left: 10px;
      }

      .text {
        @include tmobi-font-normal(13px, 13px);
        color: #f7fdf9;
        margin-left: 6px;
        em {
          @include tmobi-font-bold(13px, 13px);
        }
      }
    }
    .close_button {
      @include touch-guide(20px);
      position: absolute;
      // background: red;
      right: 0px;
      top: 0;
      height: 40px;
      width: 40px;

      border-radius: 20px;
      .arrow {
        margin-bottom: 10px;
      }
    }
  }
}

body[data-orientation='landscape'] {
  .wrap {
    width: 360px;
    right: 12px;
  }
}
