@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.wrap {
}

.banner {
  @include common-banner-style;
}

.update_banner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  @include font-size-14();
  color: $gray-400;
  padding: 50px 20px;

  background: linear-gradient(180deg, #f8f9fa 0%, #fff 100%);
}

.tooltip_wrap {
  display: flex;
  justify-content: center;
  width: 100%;

  position: absolute;
  top: 74px;
}
