@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contents_wrap {
  width: 100%;
  min-height: 138px;
  display: flex;
  flex-direction: column;

  padding: 16px 16px 0 16px;
}

.info_wrap {
  margin-top: 8px;
}

.comment_wrap {
  margin-top: 4px;

  @include font-size-14();
  @include line-clamp(1);
  color: $gray-600;
  text-overflow: ellipsis;

  &.no_poi_info {
    margin-top: 8px;
  }
}

.sub_info_wrap {
  margin-top: 4px;
}
