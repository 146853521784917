@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.wrap {
  position: relative;
}

// -------- 공통
.section {
  padding: 24px 0 0;
  background: $wb-white;

  .header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0 20px;
    .title {
      @include tmobi-font-normal(15px, 22px);
      display: flex;
      flex-direction: column;
      gap: 3px 0;
      overflow: hidden;

      .sub {
        color: $gray-900;
      }
      .main {
        @include tmobi-font-bold(20px, 26px);
        color: $gray-900;
        white-space: nowrap;
        @include text-ellipsis;
      }
    }
  }

  .rounded_content_wrap {
    background: $wb-white;
    position: relative;
    padding: 28px 0 32px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
}

// -------- 내취향 맛집 (맛집추천)
.section_similar {
  .base_poi {
    @include touch-guide;
    width: 100%;
    height: 138px;
    position: relative;
    z-index: 0;
    color: $wb-white;

    .bg_gradient {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: linear-gradient(270deg, rgba(23, 24, 25, 0) 35%, #171819 64.37%);
    }

    .bg_img {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 0;
    }

    .title_wrap {
      position: relative;
      z-index: 2;
      padding: 20px;

      .sub_title {
        @include tmobi-font-normal(13px, 18px);
        opacity: 0.7;
        margin-bottom: 2px;
      }

      .title {
        @include tmobi-font-bold(16px, 22px);
        .icon {
          display: inline-block;
        }
      }
    }
  }

  .content_wrap {
    margin-top: -38px;
  }

  .list_wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 24px 8px;
    margin: 0 20px 24px;

    .cell {
      width: calc(50% - 4px);
      overflow: hidden;
    }
  }
}

// 주말
.section_weekend {
  background: linear-gradient(180deg, #fff 0%, #f8f9fa 9.1%, #eef0f3 25.15%);

  .header {
    display: flex;
    position: relative;
    .region_select_button {
      padding: 10px 14px;
      border-radius: 36px;
      border: 1px solid $gray-200;
      @include tmobi-font-medium(13px, 18px);
      display: flex;
      align-items: center;
      background: $wb-white;
      // position: absolute;
      // right: 20px;
      // top: 0;
      flex-shrink: 0;
      @include touch-guide(36px);
      position: relative;

      .name_wrap {
        display: flex;
        align-items: center;
        margin-right: 4px;
      }
    }
  }

  .base_poi {
    padding: 0 24px 0 20px;
    margin-bottom: 20px;

    .base_poi_cont {
      @include touch-guide(12px);
      border: 1px solid $gray-100;
      border-radius: 12px;
      padding: 17px 18px;
      display: flex;
      justify-content: space-between;
      background: $wb-white;
      position: relative;
      cursor: pointer;

      .title_wrap {
        .sub_title {
          @include tmobi-font-medium(12px, 16px);
          color: $tmobi-blue-600;
          margin-bottom: 8px;
        }
        .title {
          @include tmobi-font-bold(15px, 22px);
          display: flex;
          align-items: center;
          margin-bottom: 2px;
          color: $tmobi-blue-800;
          .poi_name {
            margin-right: 2px;
          }
        }
        .desc {
          @include tmobi-font-medium(13px, 18px);
          color: $tmobi-blue-800;
        }
      }
      .img_wrap {
        width: 64px;
        height: 64px;
        border-radius: 32px;
        overflow: hidden;
      }
    }
  }

  .list_wrap {
    padding: 0 20px;
    // margin-bottom: 10px;
    .cell {
      padding: 20px 0;
      border-bottom: 1px solid $gray-100;
      overflow: hidden;

      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        border: none;
        padding-bottom: 0;
      }
    }
  }
}

// 음식점 카페
.section_delicious {
  .header {
    position: relative;
    .icon_wrap {
      position: absolute;
      top: 0;
      right: 20px;
      display: flex;
      .icon {
        width: 46px;
        height: 46px;
        background: gray;
        border-radius: 23px;
        position: relative;
        margin-right: -6px;
        &:last-child {
          margin-right: 0;
        }
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .restaurant {
        background: $tmobi-blue-100;
        z-index: 1;
        color: $tmobi-blue-400;
      }
      .cafe {
        background: rgba(227, 216, 254, 0.7);
        z-index: 0;
        color: $tmobi-purple-400;
      }
    }
  }
  .content_wrap {
    margin: 0 20px;
    position: relative;
    color: $gray-900;

    .info_button {
      position: absolute;
      right: -2px;
      top: 2px;
      padding: 4px;
      border-radius: 4px;
      display: flex;
      @include tmobi-font-normal(12px, 16px);
      @include touch-guide(4px);
      color: $gray-400;
      > span {
        margin-right: 4px;
      }
    }

    .info_tooltip {
      position: absolute;
      background: $wb-white;
      padding: 16px;
      border-radius: 4px;
      border: 1px solid $gray-200;
      z-index: 10;
      width: 100%;
      top: 24px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.14);
      h3 {
        color: $gray-600;
        @include tmobi-font-bold(14px, 20px);
        margin-bottom: 4px;
      }
      .desc {
        @include tmobi-font-normal(12px, 18px);
        color: $gray-400;
        > i {
          margin: 0 8px 0 3px;
        }
      }
      .close_button {
        position: absolute;
        right: 11px;
        top: 11px;
        padding: 5px;
        @include touch-guide(4px);
      }
    }

    .category_wrap {
      margin-bottom: 22px;
      &:last-child {
        margin-bottom: 32px;
      }
      .title {
        @include tmobi-font-bold(16px, 24px);
        margin-bottom: 12px;
      }

      .list_wrap {
        display: flex;
        gap: 0 10px;
        margin-bottom: 22px;

        .cell {
          flex: 1 1 0;
          overflow: hidden;
        }
      }
    }
  }
}

// -------- 기타
.spacer {
  height: 8px;
  background: $gray-100;

  // 컨포넌트가 특정 조건으로 미노출 할 경우 구분선이 하나만 남도록 적용
  & + & {
    display: none;
  }

  & + .ad_wrap {
    margin-top: -8px;
  }
}
