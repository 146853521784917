@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 12px;

  .filter_wrap {
    display: flex;
    gap: 6px;
    .filter_item {
      padding: 5px 11px;
      border: 1px solid $gray-200;
      border-radius: 40px;
      @include font-size-14();

      &.active {
        border-color: $gray-600;
        background: $gray-100;
        color: $gray-900;
        @include font-size-14-bold();
      }
    }
  }

  .sort_item {
    display: flex;
    align-items: center;
    gap: 2px;
    margin-right: 4px;
    @include font-size-12-medium();
    color: $gray-600;
  }
}

.modal_wrap {
  display: flex;
  flex-direction: column;
  padding-bottom: calc(20px + env(safe-area-inset-bottom));

  .sort_select_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 20px;
    @include font-size-18();

    &.active {
      @include font-size-18-bold();
      color: $tmobi-blue-500;
    }
  }
}
