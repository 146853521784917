@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

// 지역별 인기 장소
.popular_places {
  @include scroll-wrap(x);
  display: flex;
  gap: 0 8px;
  flex-wrap: nowrap;
  margin-top: 16px;
  padding: 0 20px;

  .item {
    @include touch-guide(50%);
    overflow: hidden;
    position: relative;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    display: block;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: $black-alpha-500;
    }
    .text {
      @include font-size-16-bold;
      position: absolute;
      z-index: 10;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $wb-white;
    }
    img {
      vertical-align: top;
    }
  }
}
