@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 100%;
  padding: 9px 12px;
  border-bottom: 1px solid $gray-100;

  @include font-size-12();
  color: $gray-600;

  .item {
    position: relative;

    display: flex;
    color: inherit;
    align-items: center;
    gap: 2px;

    @include override-svg($gray-300, 12px, 12px);
  }

  a.item {
    // .sort 아래에 &:is(a) 하면 되지만 사파리 13이하가 지원 안됨
    position: relative;
    @include touch-guide(5px, $top: -3px, $left: -4px, $right: -6px, $bottom: -3px);
  }
}
