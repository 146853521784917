@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.wrap {
  display: flex;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  position: fixed;
  z-index: $Z-custom-layer;

  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: column;

  width: 100%;

  $S-tmds-popup-width: 310px;

  .popup_wrap {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    width: $S-tmds-popup-width;
    height: 380px;

    background-color: $gray-100;

    border-top-right-radius: 8px;
    border-top-left-radius: 8px;

    .dot_indicator {
      position: absolute;
      z-index: $Z-popup-indicator;

      display: flex;
      justify-content: space-between;

      top: 16px;
      right: 16px;

      width: 22px;
      height: 6px;

      .item {
        width: 6px;

        border-radius: 50%;
        background-color: $C-wb-transparent-300;

        will-change: width;
        transition: width ease-in-out 0.1s;

        + .item {
          margin-left: 4px;
        }

        &.is_active {
          flex: 1;
          width: 100%;
          border-radius: 10px;
          background-color: $black-alpha-600;
        }
      }
    }

    .pagination {
      position: absolute;
      z-index: $Z-popup-indicator;

      display: flex;
      justify-content: center;
      align-items: center;

      top: 16px;
      right: 16px;

      height: 24px;
      min-width: 44px;
      padding: 0px 10px;

      opacity: 0.4;
      background-color: $wb-black;
      border-radius: 20px;

      color: $wb-white;
      @include font-size-11;
    }

    .item {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;

        border-top-right-radius: 8px;
        border-top-left-radius: 8px;

        opacity: 0;
        transition: opacity ease-in-out 0.3s;

        &.fade_in {
          opacity: 1;
        }
      }
    }
  }

  .button_wrap {
    display: flex;
    justify-content: space-between;

    width: $S-tmds-popup-width;
    height: 56px;

    padding: 17px 20px;

    background-color: $wb-white;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;

    button {
      @include font-size-16;
      color: $gray-600;
    }

    .hide_button {
      color: $gray-400;
    }
  }
}
