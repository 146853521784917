@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.wrap {
  position: relative;

  .filter {
    height: 30px;
    display: inline-flex;
    align-items: center;
    padding: 5px 10px;

    flex-shrink: 0;
    margin-right: 4px;
    position: relative;
    border: 1px solid $gray-200;
    color: $gray-900;
    border-radius: 18px;
    background-color: $wb-white;

    @include font-size-14();
    @include touch-guide(18px);

    &.on {
      @include font-size-14-bold();
      background-color: $gray-50;
      border-color: $gray-400;
      color: $gray-900;
    }

    .text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1;
    }

    &:last-child {
      margin-right: 0px;
    }
  }
}

.tooltip {
  position: absolute;
  top: 78px;
  left: 16px;

  width: 209px;

  animation: fadeout 0.5s ease-in-out forwards 4s;

  &.is_tpopular {
    left: 147px;
  }
}
