@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

/**
lagacy 스타일
**/
.wrap {
  position: relative;
}

// 티맵 AI 아이콘+툴팁
.tmap_ai_wrap {
  position: absolute;
  top: 24px;
  right: 20px;
  z-index: 100;
}

.section {
  padding: 24px 0 24px;
  background: $wb-white;

  // &:first-of-type {
  //   padding-top: 0;
  // }
  .header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0 20px 8px;
  }
  .title {
    @include tmobi-font-medium(15px, 22px);
    display: flex;
    flex-direction: column;
    gap: 3px 0;

    .sub {
      color: $gray-900;
    }
    .main {
      @include tmobi-font-bold(20px, 26px);
      color: $gray-900;
      white-space: nowrap;

      .select {
        @include touch-guide();
        position: relative;
        display: inline-flex;
        align-items: center;
        gap: 0 2px;
        margin-right: 6px;
        color: $tmobi-blue-600;
        line-height: inherit;
        font-weight: inherit;
        text-decoration-line: underline;
        text-decoration-thickness: 1px;
        text-underline-offset: 4px;
      }
    }
  }
}

.tab {
  display: flex;
  gap: 0 8px;
  padding: 7px 20px;

  .item {
    @include touch-guide(17px);
    @include tmobi-font-medium(14px, 20px);
    position: relative;
    padding: 6px 11px;
    border: 1px solid $gray-200;
    border-radius: 17px;
    color: $gray-900;

    &.active {
      border-color: transparent;
      background: $tmobi-blue-500;
      color: $wb-white;
      font-weight: 700;
    }
  }
}

.course {
  margin-top: 12px;

  .list {
    display: flex;
    flex-direction: column;
    gap: 20px 0;
    margin-left: 20px;

    .item {
      position: relative;
      padding-left: 58px;

      &::after {
        content: '';
        position: absolute;
        top: 44px;
        bottom: -20px;
        left: 20px;
        width: 44px;
        transform: translateX(-50%);
        background: url('../../../resource/images/dot_line.svg') no-repeat 50% 0;
      }
      &:last-of-type {
        &::after {
          display: none;
        }
      }
      .label {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 58px;
        background: $wb-white;

        .text {
          @include tmobi-font-bold(13px, 28px);
          display: block;
          width: 44px;
          height: 28px;
          margin-top: 8px;
          border-radius: 14px;
          background-color: $orange-500;
          color: $wb-white;
          text-align: center;

          &.place {
            background-color: $green-600;
          }
        }
      }
    }
  }
  .recommend {
    @include touch-guide(8px);
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 24px 20px 0;
    padding: 16px;
    width: calc(100% - 40px);
    border: 1px solid $gray-200;
    border-radius: 8px;

    .text {
      @include tmobi-font-normal(16px, 24px);
      display: flex;
      align-items: center;
      gap: 0 14px;
      color: $gray-900;
      text-align: left;

      .first {
        font-weight: 700;
      }
      .second {
        color: $tmobi-blue-600;
        font-weight: 700;
      }
    }
  }
}

// POI 리스트 타입
.grid_list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 8px;
  margin: 12px 20px 0;

  .cell {
    width: calc(50% - 4px);
  }
}

.row_list {
  display: flex;
  flex-direction: column;
}

.carousel_list {
  @include scroll-wrap(x);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 0 8px;
  padding-right: 20px;

  .cell {
    width: 150px;
    flex-shrink: 0;
  }
}

// 사이 광고
.ad_wrap {
  & + .spacer {
    display: none;
  }
}

// 컴포넌트 사이 공간
.spacer {
  height: 8px;
  background: $gray-100;

  // 컨포넌트가 특정 조건으로 미노출 할 경우 구분선이 하나만 남도록 적용
  & + & {
    display: none;
  }

  & + .ad_wrap {
    margin-top: -8px;
  }
}
