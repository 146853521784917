@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 80px;
  padding: 16px 20px;

  border-radius: 12px;
  border: 1px solid $gray-100;
  background: $background-surface-primary-base;
}

.text_wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  flex: 1;
  min-width: 0;
  margin-right: 12px;

  .text {
    @include font-size-16-bold();
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
  }

  .sub_text {
    @include tmobi-font-medium(13px);
    color: $gray-400;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: $gray-400;
    width: 100%;
  }
}

.image_wrap {
  width: 68px;
  height: 60px;
}
