@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.wrap {
  padding: 0 20px;
  position: relative;
  &.active {
    @include touch-guide();
  }
}

.image_content_wrap {
  position: relative;

  .tag {
    position: absolute;
    top: 6px;
    left: 6px;

    padding: 5px 6px;

    color: $text-basic-inverse;
    @include tmobi-font-bold(11px, 11px);
    background-color: $tmobi-blue-400;
    border-radius: 3px;
    z-index: 1;
  }

  .count {
    position: absolute;
    bottom: 4px;
    right: 4px;
    z-index: 1;

    padding: 7px 6px;
    border-radius: 5px;

    color: $text-button-primary;
    @include tmobi-font-bold(11px, 11px);
    background-color: $black-alpha-600;
  }
}

.image_wrap {
  display: flex;
  gap: 2px;
  border-radius: 8px;
  overflow: hidden;

  .item {
    width: calc(100% / var(--image-count, 3));
    height: 110px;

    .image {
      width: 100%;
      height: 100%;

      &.border_top_left {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      &.border_top_right {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }
}

.content_wrap {
  position: relative;
  margin-top: 14px;

  .icon_star {
    position: absolute;
    top: 0;
    right: 4px;

    display: flex;
    flex-direction: column;
    align-items: flex-end;

    width: 46px;
    height: 46px;

    i {
      position: relative;
      @include touch-guide();
    }
  }

  .title_wrap {
    width: calc(100% - 30px);

    .title {
      @include tmobi-font-bold(17px, 23px);
      color: $gray-900;
    }

    .category {
      position: relative;
      top: -1px;
      left: 4px;

      @include tmobi-font-normal(12px, 12px);
      color: $gray-400;
    }
  }

  .detail_wrap {
    display: flex;
    align-items: center;
    gap: 2px;

    margin-top: 6px;

    .review_wrap {
      display: flex;
      align-items: center;
      gap: 2px;

      .star_fill_icon {
        width: 16px;
        height: 16px;
        // ios 예외
        @supports (-webkit-touch-callout: none) {
          margin-top: -1px;
        }
      }

      .count {
        @include tmobi-font-medium(14px, 14px);
        color: $gray-600;

        em {
          @include tmobi-font-bold(14px, 14px);
        }
      }
    }

    .waiting_wrap {
      @include tmobi-font-medium(14px, 14px);
      color: $tmobi-pink-600;
    }
  }

  .etc_wrap {
    display: flex;
    align-items: center;
    gap: 2px;

    margin-top: 6px;
    color: $gray-600;

    @include tmobi-font-medium(14px, 14px);

    .tnow_count {
      color: $tmobi-blue-400;
    }
  }

  .tag_wrap {
    display: flex;
    gap: 2px;

    margin-top: 7px;
  }
}
