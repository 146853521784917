@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.wrap {
  width: 100%;
  display: flex;
  justify-content: center;

  button {
    @include tmobi-font-normal(14px, 20px);
    width: 116px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #e0e2e8;
    border-radius: 20px;
    position: relative;
    @include touch-guide(20px);

    color: $gray-600;
    .icon {
      display: inline-flex;
      margin-right: 4px;
    }
    .text {
      margin-right: 4px;
    }
    em {
      @include tmobi-font-bold(14px, 20px);
      color: $gray-900;
    }
  }
}
