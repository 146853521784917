@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.wrap {
  position: relative;
  overflow: hidden;

  .title_wrap {
    .bg {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .title_image_wrap {
      height: 237px;
      .gradient {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;

        height: 237px;
        width: 100%;

        background-color: $black-alpha-800;
        opacity: 0.55;
      }
    }

    .title {
      position: absolute;
      top: 20px;
      left: 20px;

      h4 {
        color: $text-button-primary;
        @include tmobi-font-bold(20px);
        line-height: 150%;

        margin-bottom: 8px;
      }

      .desc {
        color: $gray-100;
        line-height: 160%;
        opacity: 0.7;
        @include tmobi-font-medium(14px, 22px);
      }
    }
  }

  .list_wrap {
    position: relative;
    margin-top: -66px;

    width: 100%;
    padding: 20px;

    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    background-color: $background-surface-primary-base;

    .item {
      display: flex;
      align-items: center;

      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 4px;
      }

      .image {
        border-radius: 6px;
        width: 75px;
        height: 60px;

        outline: 1px solid $black-alpha-200;
        outline-offset: -1px;

        img {
          border-radius: 6px;
        }
      }
    }

    .poi_wrap {
      display: flex;
      flex-direction: column;

      gap: 2px;

      min-width: 0;
      margin-left: 12px;

      .poi_name {
        width: 100%;
        @include text-ellipsis;

        color: $text-basic-default;
        @include tmobi-font-bold(16px);
      }

      .detail_wrap {
        display: flex;
        align-items: center;

        color: $text-basic-description;
        @include tmobi-font-medium(14px);
      }
    }
  }
}
