@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.content_list_wrap {
  display: flex;
  flex-direction: column;

  .content_list_item {
    margin: 28px 0;

    &:first-child {
      margin-top: 0;
    }
  }

  .content_list_item + .content_list_item {
    margin-top: 0;
  }

  .content_list_item + .ranking_banner {
    margin-top: -24px;
  }
}

.spacer_gray {
  height: 8px;
  background-color: $gray-100;
  // 중복시 하나만 보이게
  & + & {
    display: none;
  }
}
