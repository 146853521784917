@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.wrap {
  position: relative;

  height: 36px;
  margin-top: 15px;
  padding: 9px 16px;

  display: inline-flex;
  align-items: center;

  border: 1px solid $gray-200;
  border-radius: 18px;

  @include font-size-12-bold();
  @include touch-guide(18px);
}

.highlight {
  color: $tmobi-pink-500;
  margin-left: 6px;
}
