@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.header {
  height: 56px;
}
.container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
}
.skeleton_wrap {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.tab {
  @include scroll-wrap(x);
  display: flex;
  gap: 0 6px;
  padding: 4px 12px 12px;
  border-bottom: 1px solid $black-alpha-100;

  .item {
    @include touch-guide(8px);
    @include font-size-14;
    position: relative;
    height: 36px;
    padding: 0 12px;
    border: 1px solid $gray-200;
    border-radius: 8px;
    color: $gray-900;

    &.active {
      background: $gray-700;
      border-color: transparent;
      color: $wb-white;
      font-weight: 700;
    }
  }
}
.error {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.visible_dom {
  position: relative;
  margin-top: -1px;
  height: 1px;
}
