@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.info_wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .operation {
    white-space: nowrap;
    @include font-size-14();
  }

  .rating_wrap {
    display: flex;
    align-items: center;
    gap: 2px;
    white-space: nowrap;
    @include font-size-14-bold();

    .count {
      @include font-size-14();
      color: $gray-600;

      .number {
        @include font-size-14-bold();
      }
    }
  }

  .waiting_wrap {
    height: 20px;
    overflow: hidden;
    width: 100%;
    @include font-size-14();

    .rolling_wrap {
      height: 22px;
      overflow: hidden;
      width: 100%;

      .content {
        transform: translateY(0);

        &.is_move {
          transition: transform ease-in-out 0.3s;
          transform: translateY(-100%);
        }
      }
    }

    .tnow_count {
      color: $tmobi-blue-400;
    }

    .waiting {
      color: $C-tmobi-pink-600;

      .score {
        margin-left: 4px;
      }
    }
  }

  .score {
    @include font-size-14-bold();
  }
}
