@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.wrap {
  display: flex;
  flex-direction: column;

  padding: 24px 0;

  h4 {
    display: flex;
    flex-direction: column;
    gap: 3px;

    @include tmobi-font-medium(15px);
    line-height: 22px;
    color: $text-basic-default;

    padding: 0 20px;

    em {
      color: $text-basic-default;
      @include tmobi-font-bold(20px);
    }
  }

  .list {
    display: flex;

    @include scroll-wrap(x);
    margin-top: 18px;

    .item {
      position: relative;

      display: flex;
      flex-shrink: 0;

      width: 230px;
      height: 329px;

      margin-right: 8px;
      border-radius: 16px;

      &:first-child {
        margin-left: 20px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        border-radius: inherit;
      }

      .gradient {
        position: absolute;
        bottom: 0;

        width: 230px;
        height: 120px;

        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;

        background: linear-gradient(180deg, transparent 0%, rgba(0, 0, 0, 0.6) 56%);
      }
    }

    .spacer {
      width: 0px;
      padding-right: 12px;
    }
  }
}
