@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.wrap {
  position: absolute;
  z-index: $Z-current-button;
  right: 16px;
  top: -56px; // width 40 + bottom 16

  .mode_button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border: 0;
    background-color: rgba(255, 255, 255, 0.95);
    opacity: 0.95;
    padding: 0;
    outline: none;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    border-radius: 100%;
  }
}
