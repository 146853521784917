@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.wrap {
  display: flex;
  position: relative;
}
.content {
  @include touch-guide();
  position: relative;
  display: flex;
  align-items: center;
  gap: 0 16px;
  width: 100%;
}

.thumb {
  @include image-holder($width: 80px, $height: 91px, $radius: 6px);
  position: relative;

  .img {
    width: inherit;
    height: inherit;
    border-radius: inherit;
  }
}

.info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  min-width: 0;

  .name {
    @include tmobi-font-bold(16px, 24px);
    display: block;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $gray-900;
    text-align: left;
    margin-bottom: 4px;
  }

  .star_review_container {
    display: flex;
    align-items: center;
    @include tmobi-font-normal(12px, 16px);
    color: $gray-600;
    margin-bottom: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    width: 100%;

    .star_point {
      @include tmobi-font-bold(12px, 16px);
      display: inline-flex;
      align-items: center;
      gap: 0 2px;
      color: $gray-900;
      margin-right: 2px;
    }

    .route_num {
      color: $gray-900;
      em {
        @include tmobi-font-bold(12px, 12px);
      }
    }
  }

  .category_container {
    @include tmobi-font-normal(12px, 16px);
    display: flex;
    align-items: center;
    width: 100%;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $gray-400;
    margin-bottom: 7px;
  }

  .tags {
    display: flex;
    gap: 0 2px;
    margin-top: 2px;

    .tag {
      line-height: 16px;
    }
  }

  .dot {
    display: inline-flex;
    margin: 0 2px;
  }

  em {
    @include tmobi-font-bold(12px, 12px);
  }

  .ellipsis {
    @include text-ellipsis;
  }
}
.favorite {
  @include touch-guide();
  position: absolute;
  top: 0px;
  right: 0px;
}
