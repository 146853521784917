@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.drawer_wrap {
  display: inline-flex;
  padding: 4px 12px 12px 12px;
  align-items: flex-start;
  gap: 6px;
  background-color: $wb-white;
  width: 100%;

  @include scroll-wrap(y);

  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;

    width: 62px;
    height: 36px;
    padding: 8px 10px 8px 12px;

    border-radius: 8px;
    border: 1px solid $gray-200;
    background: $wb-white;

    @include font-size-14-medium();

    &.clicked {
      color: $wb-white;
      background-color: $gray-700;
      border-color: $gray-700;

      @include font-size-14-bold();
    }

    &.no_icon {
      padding-right: 12px;
    }
  }
}

.drawer_line {
  width: 100%;
  height: 1px;
  background-color: $black-alpha-100;

  position: absolute;
  top: 52px;
  left: 0;

  &.is_ios {
    top: 51px;
    // 구조 변경해야될 것 같아서, 급하게 처리.
  }
}
