@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  margin: 80px 0;
}

.text {
  color: $gray-600;
  @include font-size-18-bold();
}

.button_wrap {
  display: flex;
  justify-content: center;
  align-items: center;

  .button {
    position: relative;
    height: 48px;
    padding: 12px 18px;
    color: $wb-white;
    background-color: $tmobi-blue-500;
    border-radius: 8px;

    @include touch-guide(8px);
    @include font-size-16();
  }
}
