@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.image_wrap {
  display: grid;
  width: 100%;
  grid-gap: 2px;
  grid-template-columns: repeat(var(--image-count), 1fr);
}

.image {
  position: relative;
  @include image-holder($width: 100%, $height: 110px);

  &.round_left {
    border-top-left-radius: 8px;

    &.is_card_mode {
      border-top-left-radius: 20px;
    }
  }

  &.round_right {
    border-top-right-radius: 8px;

    &.is_card_mode {
      border-top-right-radius: 20px;
    }
  }

  &:not(.is_card_mode) {
    &.round_left {
      border-bottom-left-radius: 8px;
    }

    &.round_right {
      border-bottom-right-radius: 8px;
    }
  }

  img {
    border-radius: inherit;
  }
}
