@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  padding: 16px 16px 20px 16px;
  border-bottom: 1px solid $gray-100;

  @include list-focus;
}

.img_idx {
  width: 40px;
  text-align: center;
  position: absolute;
  top: 16px;
  left: 16px;
  background-color: $black-alpha-400;
  padding: 4px 8px;
  color: $wb-white;
  border-radius: 8px 0px;
  @include tmobi-font-bold(15px);

  z-index: $Z-floating;
}

.container {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 4px;

  margin-top: 12px;
  padding-left: 4px;
}

.description {
  @include font-size-14();
  color: $gray-600;
}
