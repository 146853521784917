@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.wrap {
  position: relative;
}
.content {
  @include touch-guide();
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.thumb {
  @include image-holder($width: 100%, $height: 110px, $border: 6px);
  position: relative;
  border-radius: 6px;
  margin-bottom: 10px;

  .img {
    width: inherit;
    height: inherit;
    border-radius: inherit;
  }

  .route_num_tag {
    position: absolute;
    top: 10px;
    left: 10px;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.6);
    color: $wb-white;
    padding: 4px;
    @include tmobi-font-normal(11px, 11px);
    em {
      @include tmobi-font-bold(11px, 11px);
    }
  }
}

.info_wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  .name {
    @include tmobi-font-bold(16px, 24px);
    display: block;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $gray-900;
    text-align: left;
    margin-bottom: 4px;
  }

  .star_review_container {
    display: inline-flex;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    align-items: center;
    @include tmobi-font-normal(14px, 20px);
    color: $gray-600;
    margin-bottom: 4px;

    em {
      @include tmobi-font-bold(14px, 20px);
    }

    .star_point {
      display: inline-flex;
      align-items: center;
      gap: 0 2px;
      color: $gray-900;
      margin-right: 2px;
    }
  }

  .category_container {
    @include tmobi-font-normal(13px, 18px);
    display: flex;
    align-items: center;
    width: 100%;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    color: $gray-500;
    gap: 0 2px;
    .dot {
      display: inline-flex;
    }
  }

  .ellipsis {
    @include text-ellipsis;
  }
}

.favorite {
  @include touch-guide();
  position: absolute;
  right: 8px;
}

// 3개짜리 tiny 모드
.wrap_tiny {
  .thumb {
    margin-bottom: 10px;
    .route_num_tag {
      top: 8px;
      left: 8px;
    }
  }
  .info_wrap {
    .name {
      @include tmobi-font-bold(14px, 20px);
    }

    .star_review_container {
      margin-bottom: 4px;
      em {
        @include tmobi-font-bold(12px, 16px);
      }
      @include tmobi-font-normal(12px, 16px);
    }
  }
}
