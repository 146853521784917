@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.page_wrap {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.map_wrap {
  width: 100%;
  height: 100%;
  background-color: bisque;
  border: 3px solid rgb(240, 139, 16);

  position: relative;

  .map_position_info {
    width: 100%;
    position: absolute;
    left: 0;
    border-top: 1px solid #ccc;
    text-align: center;
  }
}

.list_wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  transform: translateY(-450px);
  background-color: white;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;

  .list_header {
    touch-action: none;

    .handle_wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 20px;

      .handle {
        width: 34px;
        height: 5px;
        border-radius: 4px;
        background-color: $gray-500;
      }
    }

    .header_content {
      height: 80px;
      background-color: $tmobi-teal-200;
      border: 3px solid $tmobi-teal-500;
    }
  }

  .scroll_wrap {
    overflow-y: scroll;
    overflow-x: hidden;
    scroll-behavior: smooth;
    flex: 1;

    .list_item {
      height: 100px;
      background-color: $C-tmobi-pink-300;
      border: 3px solid $C-tmobi-pink-400;
    }
  }
}
