@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 1 0;
  position: relative;

  padding: 20px 16px 20px 16px;
  border-bottom: 1px solid $gray-100;

  @include list-focus;
}

.ranking {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 22px;
  margin-right: 12px;

  .number {
    @include font-size-16-bold();
    width: 22px;
    height: 24px;
    text-align: center;
  }
}

.image_wrap {
  @include image-holder($width: 84px, $height: 84px, $radius: 6px);
  position: relative;
  border-radius: 6px;

  .img {
    width: inherit;
    height: inherit;
    border-radius: inherit;
  }
}

.contents_wrap {
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 1;

  .info_wrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
  }

  .title {
    @include font-size-16-bold();
    @include line-clamp(2);
    text-overflow: ellipsis;

    margin-right: 32px;
    // favorite icon width + gap => 24px + 8px
  }

  .sub_info_wrap {
    display: flex;
    align-items: center;
    gap: 2px;
    margin-top: 4px;

    color: $gray-400;
    @include tmobi-font-normal(13px);

    .distance {
      flex-shrink: 0;

      .number {
        @include tmobi-font-bold(13px);
      }
    }

    .category {
      @include line-clamp(1);
      text-overflow: hidden;
      max-width: 98px;

      color: $gray-500;
    }
  }
}

.favorite {
  display: flex;
  height: 100%;
  align-items: flex-start;
  position: absolute;
  top: 16px;
  right: 16px;
}
