@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.item_wrap {
  padding: 16px 16px 20px 20px;
  border-bottom: 1px solid $gray-100;

  @include list-focus;

  &.hidden {
    visibility: hidden;
  }

  em {
    font-style: normal;
  }
}

.fixed_back {
  width: 100%;
  height: 100%;
  background-color: $gray-50;
  position: fixed;
  left: 0;
}
