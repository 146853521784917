@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.wrap {
  padding: 24px 20px;

  .inner_wrap {
    position: relative;
    @include touch-guide(8px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid $gray-200;
    border-radius: 8px;
    padding: 18px 16px 17px 18px;

    .content_wrap {
      display: flex;
      align-items: center;
      .icon {
        width: 51px;
        height: 51px;
        margin-right: 14px;
        position: relative;
        > * {
          position: absolute;
        }
        .bg {
          z-index: 1;
        }
        .img {
          top: 8px;
          left: 4px;
        }
      }
      .content {
        color: $gray-900;
        @include tmobi-font-medium(16px, 24px);
        em {
          color: $tmobi-blue-600;
          @include tmobi-font-bold(16px, 24px);
        }
      }
    }
  }
}
