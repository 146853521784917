@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  transform: translateX(calc(50vw - 50%)) translateY(-100%);
  padding-bottom: 16px;
  z-index: $Z-refresh-button;

  &.landscape {
    transform: translateX(-50%) translateY(-100%);
    left: calc((100vw - 449px) / 2 + 68px);
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 10px 18px 10px 16px;
  background-color: rgba(255, 255, 255, 0.95);

  border-radius: 20px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);

  @include tmobi-font-bold(14px);

  &.refresh {
    padding: 9px 18px 9px 16px;
    border: 1px solid #783cff;
  }
}

.icon {
  width: 18px;
  height: 18px;
  margin-right: 1px;
}

.location {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  .text {
    line-height: 20px;
    display: flex;
    align-items: center;
    gap: 4px;

    &.long_text {
      flex-direction: column;
    }
  }

  .top_text {
    display: inline-flex;
  }

  .longText .research_text {
    margin-left: 0;
    text-align: center;
  }

  .new {
    background: linear-gradient(135deg, #783cff 0%, #0064ff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
