@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.wrap {
  display: flex;
  flex-direction: column;

  padding: 24px 0;

  h4 {
    display: flex;
    flex-direction: column;
    gap: 3px;

    @include tmobi-font-medium(15px);
    line-height: 22px;
    color: $text-basic-default;

    padding: 0 20px;

    em {
      color: $text-basic-default;
      @include tmobi-font-bold(20px);
    }
  }

  .list {
    display: flex;

    @include scroll-wrap(x);
    margin-top: 18px;

    .item {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;

      margin-right: 8px;

      &:first-child {
        margin-left: 20px;
      }

      &:last-child {
        margin-right: 20px;
      }

      .image {
        width: 156px;
        height: 110px;

        border-radius: 8px;
        background-color: $gray-300;
        outline: 1px solid $black-alpha-100;
        outline-offset: -1px;

        img {
          border-radius: inherit;
        }
      }

      .poi_name {
        margin-top: 8px;
        padding: 0 8px;
        max-width: 156px;
        @include text-ellipsis;

        @include tmobi-font-bold(16px);
        color: $text-basic-default;
      }

      .category {
        margin-top: 2px;
        padding: 0 8px;

        @include tmobi-font-medium(14px);
        color: $text-basic-description;
      }
    }
  }
}
