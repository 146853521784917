@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.wrap {
  background: $gray-50;
  border-bottom: 1px solid $gray-100;
  padding: 28px 0;
  width: 100%;

  .header {
    margin-bottom: 12px;
    color: $gray-900;
    display: flex;
    align-items: center;
    padding: 0 20px;
    h2 {
      @include tmobi-font-bold(16px, 24px);
      margin-left: 2px;
    }
  }

  .list_wrap {
    width: 100%;
    @include scroll-wrap(x);
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    grid-gap: 0 8px;
    gap: 0 8px;
    padding-right: 20px;

    .cell {
      &:first-child {
        margin-left: 20px;
      }
      width: 150px;
      flex-shrink: 0;
    }
  }
}

.item_wrap {
  width: 100%;
  overflow: hidden;
  .content {
    @include touch-guide;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    .thumb {
      // @include image-holder($width: 100%, $height: 120px, $border: 6px);
      width: 100%;
      height: 120px;
      position: relative;
      border-radius: 6px;
      margin-bottom: 12px;
      .img {
        width: inherit;
        height: inherit;
        border-radius: inherit;
      }
      .tag {
        position: absolute;
        color: $wb-white;
        @include tmobi-font-normal(11px, 16px);
        padding: 2px 6px;
        background: $tmobi-purple-500;
        top: 0;
        left: 0;
        border-radius: 4px 0;
      }
    }
    .info_wrap {
      color: $gray-900;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      margin-left: 2px;
      .name {
        @include tmobi-font-bold(14px, 20px);
        @include text-ellipsis;
        width: 100%;
        text-align: left;
        margin-bottom: 6px;
      }
      .date {
        @include tmobi-font-normal(12px, 16px);
        margin-bottom: 4px;
        @include text-ellipsis;
        width: 100%;
        text-align: left;
      }
      .region {
        color: $gray-500;
        @include tmobi-font-normal(12px, 16px);
        @include text-ellipsis;
        width: 100%;
        text-align: left;
        .visit_count {
          color: $tmobi-blue-400;
          em {
            @include tmobi-font-bold(12px, 16px);
          }
        }
        .dot {
          margin: 0 4px;
          color: $gray-300;
        }
      }
    }
  }
}
