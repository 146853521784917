@charset "UTF-8";

@import '../../@tmds/tmdscolor';
@import '../../@tmds/tmdsfonts';
@import '../../@tmds/variables/color';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';
@import '../../basics/tmdscolors';
@import '../../basics/publictranscolors';

.wrap {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 52px;
  padding: 0 20px;
  position: relative;

  .button_wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $gray-100;
    border-radius: 8px;
    position: relative;
    width: 100%;
    height: 40px;
    padding: 3px;

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $gray-700;
      position: relative;
      z-index: 2;
      width: 50%;
      height: 34px;
      margin: 0px;
      border-radius: 6px;

      @include tmobi-font-medium(14px, 15px);
      transition: all 0.3s;

      &.is_selected {
        color: $gray-900;
        @include tmobi-font-bold(14px, 15px);
      }
    }

    .selected_bg {
      position: absolute;
      z-index: 1;
      top: 3px;
      left: 30%;
      width: calc(50% - 3px);
      height: 34px;
      background-color: $wb-white;
      border-radius: 6px;
      opacity: 0;
      transition: all 0.3s ease-out;
    }
  }

  .red_dot_wrap {
    width: 4px;
    height: 16px;
    margin-left: 3px;
    position: relative;

    .red_dot_point {
      position: absolute;
      top: 1px;
      left: 0;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: $red-500;
    }
  }

  // 드로워 핸들 덮는 용도
  .handle_wrap {
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
    height: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
    touch-action: none;

    .handle {
      width: 34px;
      height: 4px;
      border-radius: 4px;
      background-color: $gray-200;
    }
  }
}
